









import { Vue, Component, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TUser } from '@/_types/user.type';
import { TContact } from '@/_types/contact.type';
import BrandingHeader from '@/_modules/standalone-company/components/branding-header/branding-header.vue';
import EventInfo from '@/_modules/standalone-company/components/event-info/event-info.vue';
import {TRequestStandaloneContactParams} from '@/_modules/standalone-contact/types/standalone-contact-store-state.type';
import EwCircularLoading from '@/_ui/ew-circular-loading/ew-circular-loading.vue';
import OpenedContactsViewer from '@/_modules/contacts/components/opened-contacts-viewer/opened-contacts-viewer.vue';
import SharedContactCard from '@/_modules/contacts/components/shared-contact-card/shared-contact-card.vue';

@Component({
  components: {
    BrandingHeader,
    EventInfo,
    EwCircularLoading,
    OpenedContactsViewer,
    SharedContactCard,
  }
})
export default class StandaloneContactView extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('_userStore/user') user: TUser;
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('standaloneContactStore/contact') contact: TContact;

  @Action('_eventStore/getEvent') getEvent: (eventId: number) => Promise<TEvent>;
  @Action('standaloneContactStore/requestStandaloneContact') requestStandaloneContact: (params: TRequestStandaloneContactParams) => Promise<TContact>;
  @Action('contactsStore/openContactCard') public openContactCard: (params: { contactId: number; startupTabName?: string }) => void;
  @Action('authStore/setStandaloneAuthVisibility') setStandaloneAuthVisibility: (isVisible: boolean) => void;

  @Watch('myself')
  private onMyselfChange(newVal: TContact): void {
    if (newVal && this.wasMeetingRequestIntended) {
      this.openContactMeetings();
    }
  }

  public savedMetaViewportTagOuterHTML: string = '';
  public wasMeetingRequestIntended: boolean = false;

  public get myUserId(): number {
    return (this.user && this.user.id) || null;
  }

  public get isRequestedContactMyself(): boolean {
    if (!this.isAuthenticated || !this.myUserId || !this.contact) {
      return false;
    }
    return this.myUserId === this.contact.user_id;
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get requestedContactId(): number {
    return this.$route.params.contactId ? parseInt(this.$route.params.contactId, 10) : null;
  }

  public get metaViewport(): HTMLMetaElement {
    const d: HTMLDocument = document as HTMLDocument;
    return d.querySelector('head meta[name=viewport]');
  }

  public created(): void {
    this.initComponent();
  }

  public manageURLHash(): void {
    const hash: string = window.location.hash.substring(1);
    if (!hash) {
      return;
    }
    if (hash === 'request-a-meeting') {
      this.wasMeetingRequestIntended = true;
      this.onContactClick(null);
    }
  }

  private async initComponent(): Promise<void> {
    const event = await this.getEvent(this.eventId);

    if (event && event.id && this.requestedContactId) {
      await this.requestStandaloneContact({eventId: this.eventId, contactId: this.requestedContactId});
      this.initHashManager();
    }
  }

  public initHashManager(): void {
    if (!this.contact) {
      window.setTimeout(this.initHashManager, 250);
    }
    this.manageURLHash();
  }

  public showAuthOverlay(): void {
    this.setStandaloneAuthVisibility(true);
  }

  public onContactClick(e: Event): boolean {
    if (e && e.target && (e.target as HTMLElement).nodeName === 'A') {
      const clickedLink = e.target as HTMLAnchorElement;
      const isEventLink: boolean = clickedLink.classList.contains('event-link');
      if (isEventLink) {
        return true;
      }
    }
    // if (this.isRequestedContactMyself) {
    //   return false;
    // }
    this.wasMeetingRequestIntended = true;
    if (!this.isAuthenticated || !this.myself) {
      this.$store.dispatch('authStore/setAuthPopupTargetRoute', this.$route);
      this.showAuthOverlay();
      return false;
    }
    if (!this.contact || !this.contact.id) {
      return false;
    }
    this.openContactMeetings();
    return true;
  }

  public openContactMeetings(): void {
    this.openContactCard({
      contactId: this.contact.id,
      startupTabName: 'meetings',
    });
  }

  public get getEventImageCurtainStyles(): {[key: string]: string} {
    const result: {[key: string]: string} = {};
    if (this.eventCurtainBackgroundImageUrl) {
      result.backgroundImage = 'url(' + this.eventCurtainBackgroundImageUrl + ')';
    }
    return result;
  }

  public get eventCurtainBackgroundImageUrl(): string {
    return (this.event && this.event.image) || '';
  }

}
